import { all, takeEvery, put, fork, call, take } from 'redux-saga/effects'

import { REQUEST_LOCALCENTER, WAIT_STERILLIZER_UPDATE, REQUEST_STERILLIZER_MODIFY, REQUEST_LOCALCENTER_MODIFY } from '../constants/LocalCenter'
import { requestLocalCenterLoading, requestLocalCenterSuccess, requestLocalCenterFailed, updateSterillizer, requestSterillizerModifySuccess, requestSterillizerModifyFailed, requestLocalCenterModifySuccess, requestLocalCenterModifyFailed } from '../actions/LocalCenter'
import AirSterillizerService from '../../services/AirSterillizerService'
import { createSignalRSterillizerUpdateChannel, closeChannel } from './createSignalRChannel';

export function* requestLocalCenter() {
    yield takeEvery(REQUEST_LOCALCENTER, function* (action) {
        try {
            yield put(requestLocalCenterLoading(true))
            const response = yield call(AirSterillizerService.getLocalCenter, action.siteid)
            yield put(requestLocalCenterSuccess(response.data))
        } catch (err) {
            yield put(requestLocalCenterFailed(err))
        }
    })
}

export function* requestLocalCenterModify() {
    yield takeEvery(REQUEST_LOCALCENTER_MODIFY, function* (action) {
        try {
            const response = yield call(AirSterillizerService.putLocalCenter, action.localcenter)
            yield put(requestLocalCenterModifySuccess(response.data))
        } catch (err) {
            yield put(requestLocalCenterModifyFailed(err))
        }
    })
}

export function* requestSterillizerModify() {
    yield takeEvery(REQUEST_STERILLIZER_MODIFY, function* (action) {
        try {
            const response = yield call(AirSterillizerService.putSterillizer, action.sterillizer, action.lteNum)
            yield put(requestSterillizerModifySuccess(response.data))
        } catch (err) {
            yield put(requestSterillizerModifyFailed(err))
        }
    })
}

export function* waitSterillizerUpdate() {
    yield takeEvery(WAIT_STERILLIZER_UPDATE, function* (action) {
        let channel
        try { 
            channel = yield call(createSignalRSterillizerUpdateChannel, "receiveSterillizerUpdate", action.connection)

            while(true) {
                const status = yield take(channel)
                console.log("Get Message from SignalR: ", status)
                yield put(updateSterillizer(status))
            }
        } catch(e) {
            console.log(e, "error")
        } finally {
            closeChannel(channel)
        }
    })
}

export default function* rootSaga() {
    yield all([
        fork(requestLocalCenter),
        fork(waitSterillizerUpdate),
        fork(requestSterillizerModify),
        fork(requestLocalCenterModify)
    ])
}