export const REQUEST_LOCALCENTER = 'REQUEST_LOCALCENTER'
export const REQUEST_LOCALCENTER_LOADING = 'REQUEST_LOCALCENTER_LOADING'
export const REQUEST_LOCALCENTER_SUCCESS = 'REQUEST_LOCALCENTER_SUCCESS'
export const REQUEST_LOCALCENTER_FAILED = 'REQUEST_LOCALCENTER_FAILED'

export const REQUEST_LOCALCENTER_MODIFY = 'REQUEST_LOCALCENTER_MODIFY';
export const REQUEST_LOCALCENTER_MODIFY_SUCCESS = 'REQUEST_LOCALCENTER_MODIFY_SUCCESS';
export const REQUEST_LOCALCENTER_MODIFY_FAILED = 'REQUEST_LOCALCENTER_MODIFY_FAILED';

export const REQUEST_STERILLIZER_MODIFY = 'REQUEST_STERILLIZER_MODIFY'
export const REQUEST_STERILLIZER_MODIFY_SUCCESS = 'REQUEST_STERILLIZER_MODIFY_SUCCESS'
export const REQUEST_STERILLIZER_MODIFY_FAILED = 'REQUEST_STERILLIZER_MODIFY_FAILED'

export const WAIT_STERILLIZER_UPDATE  = 'WAIT_STERILLIZER_UPDATE'
export const UPDATE_STERILLIZER = 'UPDATE_STERILLIZER'