import { 
    REQUEST_LOCALCENTER_LOADING, 
    REQUEST_LOCALCENTER_SUCCESS, 
    REQUEST_LOCALCENTER_FAILED,
    REQUEST_LOCALCENTER_MODIFY_SUCCESS,
    REQUEST_LOCALCENTER_MODIFY_FAILED,
    REQUEST_STERILLIZER_MODIFY_SUCCESS,
    REQUEST_STERILLIZER_MODIFY_FAILED,
    UPDATE_STERILLIZER } from '../constants/LocalCenter'

import { message } from 'antd'

const initState = {
    data: [],
    loading: false,
    err: null
}

const merge = (arr1, arr2) => {
    const map = new Map()
    arr1.forEach(item => map.set(item.id, item))
    arr2.forEach(item => map.set(item.id, { ...map.get(item.id), ...item}))
    return Array.from(map.values())
}

const localcenters = (state = initState, action) => {
    switch (action.type) {
        case REQUEST_LOCALCENTER_LOADING:
            return {
                ...state,
                loading: action.isLoading,
                err: null
            }
        case REQUEST_LOCALCENTER_SUCCESS: 
            return {
                data: merge(state.data, action.data),
                loading: false,
                err: null
            }
        case REQUEST_LOCALCENTER_FAILED: 
            return {
                data: [],
                loading: false,
                err: action.error
            }
        case REQUEST_LOCALCENTER_MODIFY_SUCCESS: 
            message.success('로컬센터 정보저장 성공')
            return {
                data: state.data.map((localcenter) => localcenter.id === action.localcenter.id ? action.localcenter : localcenter),
                loading: false,
                err: null
            }
        case REQUEST_LOCALCENTER_MODIFY_FAILED: 
            return {
                ...state,
                err: action.error
            }
        case REQUEST_STERILLIZER_MODIFY_SUCCESS: 
            message.success("살균기 정보저장 성공")
            return {
                ...state,
                data: state.data.map((localcenter) => {
                    return {
                        ...localcenter,
                        sterillizers: localcenter.sterillizers.map( (sterillizer) => sterillizer.id === action.sterillizer.id ? action.sterillizer : sterillizer)
                    }
                })
            }
        case REQUEST_STERILLIZER_MODIFY_FAILED: 
            return {
                ...state,
                err: action.error
            }
        case UPDATE_STERILLIZER: 
            return {
                ...state,
                data: state.data.map((localcenter) => {
                    return {
                        ...localcenter,
                        sterillizers: localcenter.sterillizers.map( (sterillizer) => sterillizer.id === action.status.id ? action.status : sterillizer)
                    }
                })
            }
        default:
            return state;
    }
}

export default localcenters