import { LogLevel } from "@azure/msal-browser"

export const msalConfig = {
    auth: {
        clientId: process.env.REACT_APP_AADB2C_CLIENTID,
        authority: process.env.REACT_APP_AADB2C_AUTHORITY,
        knownAuthorities: [process.env.REACT_APP_AADB2C_KNOWN_AUTHORITY],
        redirectUri: process.env.REACT_APP_AADB2C_REDIRECT_URL,
        postLogoutRedirectUri: process.env.REACT_APP_AADB2C_POST_LOGOUT_REDIRECT_URL,
        navigateToLoginRequestUrl: false
    },
    cache: {
        cacheLocation: "sessionStorage", // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {	 
        loggerOptions: {	
            loggerCallback: (level, message, containsPii) => {	
                if (containsPii) {		
                    return;		
                }		
                // eslint-disable-next-line default-case
                switch (level) {		
                    case LogLevel.Error:		
                        console.error(message);		
                        return;		
                    case LogLevel.Info:		
                        console.info(message);		
                        return;		
                    case LogLevel.Verbose:		
                        console.debug(message);		
                        return;		
                    case LogLevel.Warning:		
                        console.warn(message);		
                        return;		
                }	
            },
            piiLoggingEnabled: false,
            logLevel: LogLevel.Warning
        }	
    }
} 
export const adminLoginRequest = {
    scopes: [process.env.REACT_APP_AADB2C_ADMIN_LOGIN_SCOPE]
}
export const userLoginRequest = {
    scopes: [process.env.REACT_APP_AADB2C_USER_LOGIN_SCOPE]
}
export const loginRequest = {
    scopes: [process.env.REACT_APP_AADB2C_USER_LOGIN_SCOPE]
}
export const forgotPasswordRequest = {
    authority: process.env.REACT_APP_AADB2C_FORGET_PWD_AUTHORITY,
    redirectUri: process.env.REACT_APP_AADB2C_REDIRECT_URL,
    postLogoutRedirectUri: process.env.REACT_APP_AADB2C_POST_LOGOUT_REDIRECT_URL
}