import { all, takeEvery, put, fork, call } from 'redux-saga/effects';

import { REQUEST_STERILLIZER_WEEKLY_DATA } from '../constants/Sterillizer'
import { requestSterillizerWeeklyDataLoading, requestSterillizerWeeklyDataSuccess, requestSterillizerWeeklyDataFailed} from '../actions/Sterillizer'
import AirSterillizerService from '../../services/AirSterillizerService'

export function* requestSterillizerWeeklyDataSaga() {
    yield takeEvery(REQUEST_STERILLIZER_WEEKLY_DATA, function* (action) {
        try {
            yield put(requestSterillizerWeeklyDataLoading(true))
            const response = yield call(AirSterillizerService.getSterillizerData, action.id)
            yield put(requestSterillizerWeeklyDataSuccess(response.data))
        } catch (err) {
            yield put(requestSterillizerWeeklyDataFailed(err))
        }
    })
}

export default function* rootSaga() {
    yield all([
        fork(requestSterillizerWeeklyDataSaga)
    ])
}