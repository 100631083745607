import React from 'react'
import { useDispatch, useSelector } from "react-redux";
import { Table, Button, Form, Input, Divider  } from 'antd'
import { DeleteOutlined, UserAddOutlined } from "@ant-design/icons"
import { v4 as uuidv4 } from 'uuid';

import { requestSiteModify } from 'redux/actions/Site'

const SmsList = () => {
    const dispatch = useDispatch();
    const site = useSelector((state) => state.site.data)

    const [form] = Form.useForm()

    const columns = [
        {
            title: '이름',
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: '전화번호',
            dataIndex: 'phonenumber',
            key: 'phonenumber',
        },
        {
            title: '삭제',
            key: 'operation',
            render: (text, record) => (
                <span>
                    <Button type="link" size="small" onClick={() => removeSms(record)}>
                        <DeleteOutlined />
                    </Button>
                </span>
            ),
        },
    ]

    const removeSms = (sms) => {
        const newsite = {
            ...site,
            smsPhoneNumbers: site.smsPhoneNumbers.filter( item => item.id !== sms.id)
        }
        dispatch(requestSiteModify(newsite.id, newsite))
    }

    const onFinish = values => {
        site.smsPhoneNumbers.push({id: uuidv4(), name: values.name, phonenumber: values.phone})
        dispatch(requestSiteModify(site.id, site))
        form.setFieldsValue({
            name: '', phone: ''
          });
    }

    return (
    <>
        <Form form={form} name="horizontal_login" layout="inline" onFinish={onFinish} initialValues={{name: '', phone: ''}}>
            <Form.Item
                name="name"
                rules={[{ required: true, message: '이름을 입력해주세요.' }]}
            >
                <Input placeholder="이름" size="small" style={{width: 90}}/>
            </Form.Item>
            <Form.Item
                name="phone"
                rules={[{ required: true, message: '전화번호를 입력해주세요' }]}
            >
                <Input placeholder="전화번호" size="small" style={{width: 110}}/>
            </Form.Item>
            <Form.Item shouldUpdate={true}>
                {() => (
                <Button
                    type="primary"
                    htmlType="submit"
                    size="small"
                    disabled={
                    !form.isFieldsTouched(true) ||
                    form.getFieldsError().filter(({ errors }) => errors.length).length
                    }
                >
                    <UserAddOutlined />
                </Button>
                )}
            </Form.Item>
        </Form>
        <Divider />
        <Table columns={columns} rowKey={'id'} dataSource={site.smsPhoneNumbers ?? []} pagination={false} size={'small'} />
        
    </>
    )
};

export default SmsList;