import { REQUEST_LOCALCENTER, 
    REQUEST_LOCALCENTER_SUCCESS, 
    REQUEST_LOCALCENTER_FAILED, 
    REQUEST_LOCALCENTER_LOADING,

    REQUEST_LOCALCENTER_MODIFY,
    REQUEST_LOCALCENTER_MODIFY_SUCCESS,
    REQUEST_LOCALCENTER_MODIFY_FAILED,

    REQUEST_STERILLIZER_MODIFY,
    REQUEST_STERILLIZER_MODIFY_SUCCESS,
    REQUEST_STERILLIZER_MODIFY_FAILED,
    
    UPDATE_STERILLIZER,
    WAIT_STERILLIZER_UPDATE } from '../constants/LocalCenter'

export const requestLocalCenters = (siteid) => {
    return {
        type: REQUEST_LOCALCENTER,
        siteid
    }
}

export const requestLocalCenterLoading = (isLoading) => {
    return {
        type: REQUEST_LOCALCENTER_LOADING,
        isLoading
    }
}

export const requestLocalCenterSuccess = (data) => {
    return {
        type: REQUEST_LOCALCENTER_SUCCESS,
        data
    }
}

export const requestLocalCenterFailed = (error) => {
    return {
        type: REQUEST_LOCALCENTER_FAILED,
        error
    }
}

export const requestLocalCenterModify = (localcenter) => {
    return {
        type: REQUEST_LOCALCENTER_MODIFY,
        localcenter
    }
}

export const requestLocalCenterModifySuccess = (localcenter) => {
    return {
        type: REQUEST_LOCALCENTER_MODIFY_SUCCESS,
        localcenter
    }
}

export const requestLocalCenterModifyFailed = (error) => {
    return {
        type: REQUEST_LOCALCENTER_MODIFY_FAILED,
        error
    }
}

export const requestSterillizerModify = (sterillizer, lteNum) => {
    return {
        type: REQUEST_STERILLIZER_MODIFY,
        sterillizer,
        lteNum
    }
}

export const requestSterillizerModifySuccess = (sterillizer) => {
    return {
        type: REQUEST_STERILLIZER_MODIFY_SUCCESS,
        sterillizer
    }
}

export const requestSterillizerModifyFailed = (error) => {
    return {
        type: REQUEST_STERILLIZER_MODIFY_FAILED,
        error
    }
}

export const updateSterillizer = (status) => {
    return {
        type: UPDATE_STERILLIZER,
        status
    }
}

export const waitSterillizerUpdate = (connection) => {
    return {
        type: WAIT_STERILLIZER_UPDATE,
        connection
    }
}