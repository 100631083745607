import { 
    REQUEST_SITES_SUCCESS, 
    REQUEST_SITES_FAILED, 
    REQUEST_SITES_LOADING,
    REQUEST_SITES_MODIFY_SUCCESS,
    REQUEST_SITES_MODIFY_FAILED
 } from '../constants/Site'

const initState = {
    data: null,
    loading: false,
    err: null
}

const site = (state = initState, action) => {
    switch (action.type) {
        case REQUEST_SITES_LOADING:
            return {
                ...state,
                loading: action.isLoading,
                err: null
            }
        case REQUEST_SITES_SUCCESS: 
            return {
                ...state,
                data: action.data,
                loading: false,
                err: null
            }
        case REQUEST_SITES_FAILED: 
            return {
                ...state,
                data: [],
                loading: false,
                err: action.error
            }
        case REQUEST_SITES_MODIFY_SUCCESS:
            return {
                ...state,
                data: action.data,
                loading: false,
                err: null
            }
        case REQUEST_SITES_MODIFY_FAILED: 
            return {
                ...state, 
                loading: false,
                err: action.error
            }
        default:
            return state;
    }
}


export default site