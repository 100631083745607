import React, { useEffect } from "react";
import { useSelector } from "react-redux"
import { notification } from 'antd'
import { useHistory } from "react-router-dom"
import { APP_PREFIX_PATH } from 'configs/AppConfig'
import {  UserOutlined } from '@ant-design/icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSmog, faThermometerEmpty, faExclamationCircle, faWind, faMaskFace, faThermometerFull, faDroplet } from '@fortawesome/free-solid-svg-icons'


// 공기질, CO2
const getAlertIcon = ({type}) => {
    if (type === "tvoc") return (<FontAwesomeIcon icon={faWind} style={{color: "blue"}}/>)
    else if (type === "CO2") return (<FontAwesomeIcon icon={faSmog} style={{color: "green"}} />)
    else if (type === "CO") return (<FontAwesomeIcon icon={faSmog} style={{color: "yellow"}} />)
    else if (type === "O2") return (<FontAwesomeIcon icon={faSmog} style={{color: "red"}}/>)
    else if (type === "PM1.0") return (<FontAwesomeIcon icon={faMaskFace} style={{color: "red"}}/>)
    else if (type === "PM2.5") return (<FontAwesomeIcon icon={faMaskFace} style={{color: "red"}}/>)
    else if (type === "PM10") return (<FontAwesomeIcon icon={faMaskFace} style={{color: "red"}}/>)
    else if (type === "HighTemp") return (<FontAwesomeIcon icon={faThermometerFull} style={{color: "red"}}/>)
    else if (type === "LowTemp") return (<FontAwesomeIcon icon={faThermometerEmpty} style={{color: "red"}}/>)
    else if (type === "HighHumidity") return (<FontAwesomeIcon icon={faDroplet} style={{color: "red"}}/>)
    else if (type === "LowHumidity") return (<FontAwesomeIcon icon={faDroplet} style={{color: "red"}}/>)
    else if (type.endsWith("Error")) return (<FontAwesomeIcon icon={faExclamationCircle} style={{color: "gray"}} />)
    else return (<UserOutlined />)
}

const getAlertTitle = ({type}) => {
    if (type === "tvoc") return "공기질 나쁨"
    else if (type === "CO2") return "이산화탄소(CO2) 농도 상승"
    else if (type === "CO") return "일산화탄소(CO) 농도 상승"
    else if (type === "O2") return "산소(O2) 농도 감소"
    else if (type === "PM1.0") return "PM1.0 농도 증가"
    else if (type === "PM2.5") return "PM2.5 농도 증가"
    else if (type === "PM10") return "PM10 농도 증가"
    else if (type === "HighTemp") return "높은온도 감지"
    else if (type === "LowTemp") return "낮은온도 감지"
    else if (type === "HighHumidity") return "높은습도 감지"
    else if (type === "LowHumidity") return "낮은습도 감지"
    else if (type.endsWith("Error")) return "센서오류"
    else return ""
}

const AlertNotification = () => {
    let history = useHistory();
    const recentAlert = useSelector((state) => state.alert.recentAlert)

    useEffect(() => {
        if (recentAlert) {
            const notificationParam = {
                key: recentAlert.id,
                message: getAlertTitle(recentAlert),
                description: recentAlert.description,
                onClick: () => {
                    // 디바이스 상세로 가죠. 
                    history.push(`${APP_PREFIX_PATH}/device-detail/${recentAlert.sterillizerId}`)
                },
                duration: 0,
                icon: getAlertIcon(recentAlert)
            }
            notification.error(notificationParam)
        }
        // eslint-disable-next-line 
    }, [recentAlert])

    return null;
}

export default AlertNotification