import { 
  //DashboardOutlined,
  GlobalOutlined,
  AlertOutlined,
  MonitorOutlined,
  InfoCircleOutlined
} from '@ant-design/icons';
import { APP_PREFIX_PATH } from 'configs/AppConfig'

// const dashBoardNavTree = [{
//   key: 'monitor',
//   path: `${APP_PREFIX_PATH}/monitor`,
//   title: 'sidenav.monitor.map',
//   icon: DashboardOutlined,
//   breadcrumb: false,
//   submenu: []
// }]

const monitorNavTree = [{
  key: 'monitor',
  path: `${APP_PREFIX_PATH}/monitor`,
  title: 'sidenav.monitor',
  icon: MonitorOutlined,
  breadcrumb: false,
  submenu: [
  // {
  //   key: 'dashboards',
  //   path: `${APP_PREFIX_PATH}/dashboards`,
  //   title: 'sidenav.dashboard',
  //   icon: DashboardOutlined,
  //   breadcrumb: false,
  //   submenu: []
  // },
  {
    key: 'monitor-map',
    path: `${APP_PREFIX_PATH}/monitor`,
    title: 'sidenav.monitor.map',
    icon: GlobalOutlined,
    breadcrumb: false,
    submenu: []
  },
  {
    key: 'alerts',
    path: `${APP_PREFIX_PATH}/alerts`,
    title: 'sidenav.alert',
    icon: AlertOutlined,
    breadcrumb: false,
    submenu: []
  }]
}]

const deviceNavTree = [{
  key: 'devices',
  path: `${APP_PREFIX_PATH}/devices`,
  title: 'sidenav.device',
  icon: InfoCircleOutlined,
  breadcrumb: false,
  submenu: [{
    key: 'devices-list',
    path: `${APP_PREFIX_PATH}/devices`,
    title: 'sidenav.device.list',
    icon: InfoCircleOutlined,
    breadcrumb: false,
    submenu: []
  }]
}]


const navigationConfig = [
  ...monitorNavTree,
  ...deviceNavTree
]

export default navigationConfig;
