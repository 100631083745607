import { message } from 'antd'
import { REQUEST_ALERTS_SUCCESS, 
    REQUEST_ALERTS_FAILED, 
    REQUEST_ALERTS_LOADING, 
    REQUEST_ALERTS_MODIFY_SUCCESS, 
    REQUEST_ALERTS_MODIFY_FAILED,
    ADD_RECENT_ALERT,
    REQUEST_ALL_ALERTS_DONE_SUCCESS,
    REQUEST_ALL_ALERTS_DONE_FAILED } from '../constants/Alerts'

const initState = {
    data: [],
    loading: false,
    err: null,
    recentAlert: null
}

const alerts = (state = initState, action) => {
    switch (action.type) {
        case REQUEST_ALERTS_LOADING:
            return {
                ...state,
                loading: action.isLoading,
                err: null
            }
        case REQUEST_ALERTS_SUCCESS: 
            return {
                ...state,
                data: action.data,
                loading: false,
                err: null
            }
        case REQUEST_ALERTS_FAILED: 
            return {
                ...state,
                data: [],
                loading: false,
                err: action.error
            }
        case REQUEST_ALERTS_MODIFY_SUCCESS: 
            return {
                ...state,
                err: null,
                data: state.data.map( alert => alert.id === action.alert.id ? action.alert : alert)
            }
        case REQUEST_ALERTS_MODIFY_FAILED: 
            return {
                ...state,
                err: action.error
            }
        case ADD_RECENT_ALERT: 
            const recentAlert = [action.alert]
            return {
                ...state,
                data: state.data.some( e => e.id === action.alert.id) ? state.data : recentAlert.concat(state.data),
                recentAlert: action.alert
            }
        case REQUEST_ALL_ALERTS_DONE_SUCCESS: 
            message.success('모든 알람 완료처리 성공')
            return {
                ...state,
                data: action.data,
                loading: false,
                err: null
            }
        case REQUEST_ALL_ALERTS_DONE_FAILED: 
            message.error('알람 완료처리 실패')
            return {
                ...state,
                data: [],
                loading: false,
                err: action.error
            }
        default:
            return state;
    }
}

export default alerts