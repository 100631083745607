import { all, takeEvery, put, fork, call } from 'redux-saga/effects';

import { REQUEST_SITES, REQUEST_SITES_MODIFY } from '../constants/Site'
import { requestSitesLoading, requestSitesSuccess, requestSitesFailed, requestSiteModifySuccess, requestSiteModifyFailed} from '../actions/Site'
import AirSterillizerService from 'services/AirSterillizerService'

export function* requestSite() {
    yield takeEvery(REQUEST_SITES, function* (action) {
        try {
            yield put(requestSitesLoading(true))
            const response = yield call(AirSterillizerService.getSite, action.siteid)
            yield put(requestSitesSuccess(response.data))
        } catch (err) {
            yield put(requestSitesFailed(err))
        }
    })
}

export function* requestSiteModify() {
    yield takeEvery(REQUEST_SITES_MODIFY, function* (action) {
        try {
            const response = yield call(AirSterillizerService.putSite, action.id, action.site)
            yield put(requestSiteModifySuccess(response.data))
        } catch (err) {
            yield put(requestSiteModifyFailed(err))
        }
    })
}

export default function* rootSaga() {
    yield all([
        fork(requestSite),
        fork(requestSiteModify)
    ])
}