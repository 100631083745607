import { HubConnectionBuilder, HubConnectionState, JsonHubProtocol, LogLevel } from '@microsoft/signalr';
import { SIGNALR_BASE_URL } from 'configs/AppConfig'
import { AUTH_TOKEN } from 'redux/constants/Auth'
import { changeSignalRConnection } from 'redux/actions/SignalR'
import store from 'redux/store'

const isDev = process.env.NODE_ENV === 'development';

const startSignalRConnection = async connection => {
    try { 
        await connection.start(); 
        store.dispatch(changeSignalRConnection('connected'))
        console.assert(connection.state === HubConnectionState.Connected);
        console.log('SignalR connection established.')
    } catch (err) {
        console.assert(connection.state === HubConnectionState.Disconnected)
        console.error('SignalR connection Error:', err)
        store.dispatch(changeSignalRConnection('disconnected'))
        setTimeout(() => startSignalRConnection(connection), 5000)
    }
}

export const setupSignalRConnection = () => {
    
    const options = { 
        logMessageContent: isDev,
        logger: isDev ? LogLevel.Warning : LogLevel.Error,
        accessTokenFactory: () => localStorage.getItem(AUTH_TOKEN)
    }

    const connection = new HubConnectionBuilder()
                        .withUrl(SIGNALR_BASE_URL, options)
                        .withAutomaticReconnect()
                        .withHubProtocol(new JsonHubProtocol())
                        .configureLogging(LogLevel.Information)
                        .build()

    connection.onclose(error => {
        store.dispatch(changeSignalRConnection('disconnected'))
        console.assert(connection.state === HubConnectionState.Disconnected, error);
       
    })

    connection.onreconnecting(error => {
        store.dispatch(changeSignalRConnection('reconnecting'))
        console.assert(connection.state === HubConnectionState.Reconnecting, error);
    })

    connection.onreconnected(connectionId => {
        store.dispatch(changeSignalRConnection('connected'))
        console.assert(connection.state === HubConnectionState.Connected, connectionId);
    })

    startSignalRConnection(connection)

    return connection
}

// https://stackoverflow.com/questions/46190574/how-to-import-signalr-in-react-component/62162742#62162742