import fetch from 'auth/FetchInterceptor'

const AirSterillizerService = {}

AirSterillizerService.getSite = (siteid) => {
    return fetch({
        url: `/api/site/${siteid}`,
        method: 'get'
    })
}

AirSterillizerService.putSite = (siteid, site) => {
  return fetch({
    url: `/api/site/${siteid}`,
    method: 'put',
    data: site
  })
}

AirSterillizerService.getLocalCenter = (siteid) => {
    return fetch({
        url: '/api/localcenter',
        method: 'get',
        params: {
            siteid
        }
    })
}

AirSterillizerService.putLocalCenter = (localcenter) => {
    
  console.log("localcenter", localcenter)
  
  return fetch({
    url:`/api/localcenter/${localcenter.id}`,
    method: 'put',
    data: localcenter
  })
}

AirSterillizerService.putSterillizer = (sterillizer, lteNum) => {
  return fetch({
    url: `/api/sterillizer/${sterillizer.sterillizerId}`,
    method: 'put',
    data: sterillizer,
    params: {
      localcenterid: lteNum
    }
  })
}

AirSterillizerService.getAlerts = (siteid, start, end) => {
  return fetch({
    url: '/api/alerts',
    method: 'get',
    params: {
      siteid,
      start,
      end
    }
  })
}

AirSterillizerService.putAlert = (alert) => {
  return fetch({
    url: `/api/alerts/${alert.id}`,
    method: 'put',
    data: alert
  })
}

AirSterillizerService.getAllAlertsDone = (siteid, start, end) => {
  return fetch({
    url: '/api/alertsdone',
    method: 'put',
    params: {
      siteid,
      start,
      end
    }
  })
}

AirSterillizerService.downloadAlerts = (siteid, start, end) => {
  return fetch({
    url: '/export/alert',
    method: 'get',
    responseType: 'blob',
    params: {
      siteid,
      start,
      end
    }
  })
}

AirSterillizerService.getSterillizerData = (id) => {
  return fetch({
    url: `/api/data/${id}`,
    method: 'get'
  })
}

export default AirSterillizerService