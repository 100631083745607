import { REQUEST_ALERTS, 
    REQUEST_ALERTS_SUCCESS, 
    REQUEST_ALERTS_FAILED, 
    REQUEST_ALERTS_LOADING, 
    REQUEST_ALERTS_MODIFY, 
    REQUEST_ALERTS_MODIFY_SUCCESS, 
    REQUEST_ALERTS_MODIFY_FAILED,
    ADD_RECENT_ALERT,
    WAIT_RECENT_ALERT,
    REQUEST_ALL_ALERTS_DONE,
    REQUEST_ALL_ALERTS_DONE_SUCCESS,
    REQUEST_ALL_ALERTS_DONE_FAILED } from '../constants/Alerts'


    export const requestAlerts = (siteid, start, end) => {
        return {
            type: REQUEST_ALERTS,
            siteid,
            start,
            end
        }
    }
    
    export const requestAlertsLoading = (isLoading) => {
        return {
            type: REQUEST_ALERTS_LOADING,
            isLoading
        }
    }
    
    export const requestAlertsSuccess = (data) => {
        return {
            type: REQUEST_ALERTS_SUCCESS,
            data
        }
    }
    
    export const requestAlertsFailed = (error) => {
        return {
            type: REQUEST_ALERTS_FAILED,
            error
        }
    }
    
    export const requestAlertsModify = (alert) => {
        return {
            type: REQUEST_ALERTS_MODIFY,
            alert
        }
    }
    
    export const requestAlertsModifySuccess = (alert) => {
        return {
            type: REQUEST_ALERTS_MODIFY_SUCCESS,
            alert
        }
    }
    
    export const requestAlertsModifyFailed = (error) => {
        return {
            type: REQUEST_ALERTS_MODIFY_FAILED,
            error
        }
    }
    
    export const waitRecentAlert = (connection) => {
        return {
            type: WAIT_RECENT_ALERT,
            connection
        }
        
    }
    
    export const addRecentAlert = (alert) => {
        return {
            type: ADD_RECENT_ALERT,
            alert
        }
    }
    
    export const requestAllAlertsDone = (siteid, start, end) => {
        return {
            type: REQUEST_ALL_ALERTS_DONE,
            siteid,
            start,
            end
        }
    }
    
    export const requestAllAlertsDoneSuccess = (data) => {
        return {
            type: REQUEST_ALL_ALERTS_DONE_SUCCESS,
            data
        }
    }
    
    export const requestAllAlertsDoneFailed = (error) => {
        return {
            type: REQUEST_ALL_ALERTS_DONE_FAILED,
            error
        }
    }