import React, { useState } from 'react';
import { SettingOutlined } from '@ant-design/icons';
import { Drawer, Menu } from 'antd';
import ThemeConfigurator from './ThemeConfigurator';
import { DIR_RTL } from 'constants/ThemeConstant';
import IntlMessage from "../util-components/IntlMessage";


const NavPanel = ( {direction} ) => {
  const [visible, setVisible] = useState(false)

  const setLocale = (isLocaleOn, localeKey) =>
      isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();

  const showDrawer = () => {
    setVisible(true)
  };

  const onClose = () => {
    setVisible(false)
	};

  return (
    <>
      <Menu mode="horizontal">
        <Menu.Item onClick={showDrawer}>
          <SettingOutlined className="nav-icon mr-0" />
        </Menu.Item>
      </Menu>
      <Drawer
        title={setLocale(true, 'navpanel.title')}
        placement={direction === DIR_RTL ? 'left' : 'right'} 
        width={350}
        onClose={onClose}
        visible={visible}
      >
        <ThemeConfigurator />
      </Drawer>
    </>
  )
}

export default NavPanel