import { 
    REQUEST_STERILLIZER_WEEKLY_DATA,
    REQUEST_STERILLIZER_WEEKLY_DATA_LOADING,
    REQUEST_STERILLIZER_WEEKLY_DATA_SUCCESS,
    REQUEST_STERILLIZER_WEEKLY_DATA_FAILED
} from '../constants/Sterillizer'

export const requestSterillizerWeeklyData = (id) => {
    return {
        type: REQUEST_STERILLIZER_WEEKLY_DATA,
        id
    }
}

export const requestSterillizerWeeklyDataLoading = (isLoading) => {
    return {
        type: REQUEST_STERILLIZER_WEEKLY_DATA_LOADING,
        isLoading
    }
}

export const requestSterillizerWeeklyDataSuccess = (data) => {
    return {
        type: REQUEST_STERILLIZER_WEEKLY_DATA_SUCCESS,
        data
    }
}

export const requestSterillizerWeeklyDataFailed = (error) => {
    return {
        type: REQUEST_STERILLIZER_WEEKLY_DATA_FAILED,
        error
    }
}