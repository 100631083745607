import { REQUEST_SITES, 
        REQUEST_SITES_SUCCESS, 
        REQUEST_SITES_FAILED, 
        REQUEST_SITES_LOADING, 
        REQUEST_SITES_MODIFY,
        REQUEST_SITES_MODIFY_SUCCESS,
        REQUEST_SITES_MODIFY_FAILED
     } from '../constants/Site'

export const requestSites = (siteid) => {
    return {
        type: REQUEST_SITES,
        siteid
    }
}

export const requestSitesLoading = (isLoading) => {
    return {
        type: REQUEST_SITES_LOADING,
        isLoading
    }
}

export const requestSitesSuccess = (data) => {
    return {
        type: REQUEST_SITES_SUCCESS,
        data
    }
}

export const requestSitesFailed = (error) => {
    return {
        type: REQUEST_SITES_FAILED,
        error
    }
}

export const requestSiteModify = (id, site) => {
    console.log("newsite", site)
    return {
        type: REQUEST_SITES_MODIFY,
        id,
        site
    }
}

export const requestSiteModifySuccess = (data) => {
    return {
        type: REQUEST_SITES_MODIFY_SUCCESS,
        data
    }
}

export const requestSiteModifyFailed = (error) => {
    return {
        type: REQUEST_SITES_MODIFY_FAILED,
        error
    }
}