import { all } from 'redux-saga/effects';
import Site from './Site'
import LocalCenter from './LocalCenter'
import Alert from './Alerts'
import Sterillizer from './Sterillizer'

export default function* rootSaga(getState) {
  yield all([
    Site(),
    LocalCenter(),
    Alert(),
    Sterillizer()
  ]);
}
