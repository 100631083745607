import React, { lazy, Suspense, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from 'components/shared-components/Loading';
import { APP_PREFIX_PATH } from 'configs/AppConfig'
import { useMsal } from "@azure/msal-react"
import { InteractionStatus } from "@azure/msal-browser";
import { requestSites } from 'redux/actions/Site'
import { requestAlerts } from 'redux/actions/Alerts'
import { setupSignalRConnection } from 'services/SignalRService';
import { waitSterillizerUpdate } from 'redux/actions/LocalCenter'
import { waitRecentAlert } from 'redux/actions/Alerts'

import { userLoginRequest, adminLoginRequest  } from "../../configs/AADB2CConfig";
import { AUTH_TOKEN } from 'redux/constants/Auth'
import AlertNotification from './alerts/AlertNotification'

export const AppViews = () => {
  const dispatch = useDispatch()
  const { instance, inProgress } = useMsal()
  
  const [atsResponse, setAtsResponse] = useState(null)
  const site = useSelector((state) => state.site.data)

  // Get account info (id token)
  const account = instance.getActiveAccount()

  const loginSuccess = (response) => {
    localStorage.setItem(AUTH_TOKEN, response.accessToken);
    setAtsResponse(response);

    setInterval(() => {
      var authreq = account.idTokenClaims.extension_UserType === "Admin" ? {...adminLoginRequest, account: account} : {...userLoginRequest, account: account}
      instance.acquireTokenSilent(authreq).then((res) => {
        localStorage.setItem(AUTH_TOKEN, res.accessToken);
        setAtsResponse(res);
      });
    }, 3600000);

    if (account) dispatch(requestSites(account.idTokenClaims.extension_SiteId))
  }

  useEffect(() => {
    if (!atsResponse && account && inProgress === InteractionStatus.None) {
        var authReqeust = account.idTokenClaims.extension_UserType === "Admin" ? {...adminLoginRequest, account: account} : {...userLoginRequest, account: account}
        instance.acquireTokenSilent(authReqeust).then((res) => {
          loginSuccess(res)
        })
        .catch((err) => {
          console.log("silent token acquisition fails. acquiring token using popup");
          return instance.acquireTokenRedirect(authReqeust).then(tokenResponse => {
            loginSuccess(tokenResponse)
          }).catch(error => {
              console.log(error);
          });
        });
    }
    // eslint-disable-next-line
  }, [account, inProgress, instance, atsResponse, dispatch]);

  useEffect(() => {
    // 한번만 호출되어야 하는데 ... 
    const connection = setupSignalRConnection()
    dispatch(waitRecentAlert(connection))
    dispatch(waitSterillizerUpdate(connection))
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    // get alerts
    if (site) dispatch(requestAlerts(site.id))
  }, [dispatch, site])

  return (
    <Suspense fallback={<Loading cover="content"/>}>
      <AlertNotification />
      <Switch>
        <Route path={`${APP_PREFIX_PATH}/monitor`} component={lazy(() => import(`./monitor`))} />
        {/* <Route path={`${APP_PREFIX_PATH}/dashboards`} exact component={lazy(() => import(`./dashboards`))} /> */}
        <Route path={`${APP_PREFIX_PATH}/alerts`} exact component={lazy(() => import(`./alerts`))} />
        <Route path={`${APP_PREFIX_PATH}/devices`} exact component={lazy(() => import(`./devices`))} />
        <Route path={`${APP_PREFIX_PATH}/device-detail/:id`} exact component={lazy(() => import(`./device-form`))} />
        <Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/monitor`} />
      </Switch>
    </Suspense>
  )
}

export default React.memo(AppViews)