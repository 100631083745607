import { combineReducers } from 'redux'
import Theme from './Theme'
import Site from './Site'
import LocalCenter from './LocalCenter'
import SignalR from './SignalR'
import Alert from './Alerts'
import Sterillizer from './Sterillizer'

const reducers = combineReducers({
    theme: Theme,
    site: Site,
    localcenters: LocalCenter,
    signalr: SignalR,
    alert: Alert,
    sterillizer: Sterillizer
});

export default reducers